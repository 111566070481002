/**
 * Spinners
 * --------------------------------------------------
 */

.spinner {
  svg {
    width: $spinner-width;
    height: $spinner-height;
  }

  stroke: $spinner-default-stroke;
  fill: $spinner-default-fill;

  &.spinner-light {
    stroke: $spinner-light-stroke;
    fill: $spinner-light-fill;
  }
  &.spinner-stable {
    stroke: $spinner-stable-stroke;
    fill: $spinner-stable-fill;
  }
  &.spinner-positive {
    stroke: $spinner-positive-stroke;
    fill: $spinner-positive-fill;
  }
  &.spinner-calm {
    stroke: $spinner-calm-stroke;
    fill: $spinner-calm-fill;
  }
  &.spinner-balanced {
    stroke: $spinner-balanced-stroke;
    fill: $spinner-balanced-fill;
  }
  &.spinner-assertive {
    stroke: $spinner-assertive-stroke;
    fill: $spinner-assertive-fill;
  }
  &.spinner-energized {
    stroke: $spinner-energized-stroke;
    fill: $spinner-energized-fill;
  }
  &.spinner-royal {
    stroke: $spinner-royal-stroke;
    fill: $spinner-royal-fill;
  }
  &.spinner-dark {
    stroke: $spinner-dark-stroke;
    fill: $spinner-dark-fill;
  }
}

.spinner-android {
  stroke: #4b8bf4;
}

.spinner-ios,
.spinner-ios-small {
  stroke: #69717d;
}

.spinner-spiral {
  .stop1 {
    stop-color: $spinner-light-fill;
    stop-opacity: 0;
  }

  &.spinner-light {
    .stop1 {
      stop-color: $spinner-default-fill;
    }
    .stop2 {
      stop-color: $spinner-light-fill;
    }
  }
}
