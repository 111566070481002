/*
To customize the look and feel of Ionic, you can override the variables
in ionic's _variables.scss file.

For example, you might change some of the default colors:

$light:                           #fff !default;
$stable:                          #f8f8f8 !default;
$positive:                        #387ef5 !default;
$calm:                            #11c1f3 !default;
$balanced:                        #33cd5f !default;
$energized:                       #ffc900 !default;
$assertive:                       #ef473a !default;
$royal:                           #886aea !default;
$dark:                            #444 !default;
*/

// $calm: #= !default;
// $positive: #= !default;

// The path for our ionicons font files, relative to the built CSS in www/css
$ionicons-font-path: "../lib/ionic/fonts" !default;
$positive:                        #3ED78F !default;
$navbartext: black;
$navbarbkg: white;


$cic1 : #009CDC;
$cic2: #525251;


/* GEFCO

$gefco1: rgb(0,83,139);
$gefco2: rgb(255,205,0);
$positive: $gefco1;
$calm: $gefco2;
$navbartext: white;
$navbarbkg: $gefco1;

*/

/*
$lc: #e41111;
$calm:  $lc;
$positive:  $lc;
*/


/*$calm:  $cic1;
$positive:  $cic2;*/


/*

Header : #FFFFFF
Couleur 1 : #009CDC
Couleur 2 : #42ABAD
*/

/*$calm:  #009CDC;
$positive:  #009CDC;*/


/* xerox 

$calm: #D71F26;
$positive: #D71F26;
*/
/*$calm: #3B77AE;
$positive: #3B77AE;*/





.advertising1{
  bottom: 20px;
  position: absolute;
  z-index: 300;
  text-align: right;
  color: white;
  right: 0;
  margin: 5px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  right: 6px;
}
.advertising2{
  bottom: 2px;
  position: absolute;
  z-index: 300;
  text-align: right;
  color: white;
  right: 0;
  margin: 5px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  right: 6px;
}

/*
  // background-color:#63E0A3!important;
button.button-positive:hover{
    background-color: #009CDC;
}
ion-header-bar.bar-positive:hover{
    background-color: #009CDC;
}
ion-item.highlighted:hover  {
    background-color: #009CDC;
}
ion-item.item-avatar {
    // padding-left:20px;
    // padding-right:75px;
    // width:%
}
*/


.btn-wrap {
overflow: visible!important;
white-space: normal!important;
}
@media screen and (max-height: 1100px) {
  #bar {
    max-height:880px!important;
  }
}
@media screen and (max-height: 1000px) {
  #bar {
    max-height:780px!important;
  }
}
@media screen and (max-height: 900px) {
  #bar {
    max-height:680px!important;
  }
}

@media screen and (max-height: 800px) {
  #bar {
    max-height:580px!important;
  }
}

.chat-item.ng-enter.ng-enter-active,
.chat-item.ng-leave {
    opacity: 1;
    -webkit-transition: opacity 500ms linear;
    -moz-transition: opacity 500ms linear;
    transition: opacity 500ms linear;
}

/* Remove animation */
.chat-item.ng-leave.ng-leave-active,
.chat-item.ng-enter {
    opacity: 0;
    -webkit-transition: opacity 500ms linear;
    -moz-transition: opacity 500ms linear;
    transition: opacity 500ms linear;
}
.wrap {
  padding:5px;
}
.chat {
    background-color: #EEE;
    border-radius:2px;
    // padding:7px;
    // width:96%;
    box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.16), 0px 2px 10px 0px rgba(0, 0, 0, 0.12);
    // padding-right:20px;

  }
.button.button-question {
  min-height:33px!important;
  margin-left:5px!important;
  // height:33px;
  line-height: inherit;
  max-width:30px;
  padding:0;
}
// .no-padding {
//   padding:0px;
// }
.tab-nav.tabs {
  display:none;
}
.bar.bar-header .button + .title{
  text-align:center!important;
}

// Include all of Ionic
@import "www/lib/ionic/scss/ionic";



.item {
  padding: 8px;
}


.vote-title {
  font-size:1.3rem;
  white-space:normal;
  line-height:1em;

  * {
    white-space:normal;
  }
}

.card-status-footer {
  padding-top:0;
  padding-bottom:0;
}

.card-status-header.item {
  height:auto !important;
  line-height:1em;
}

.votes-list.list .item {
 min-height:32px;
}

.votes-list.item-radio {
  .item-content {
    padding:6px 16px 6px 16px !important;
  }
  .radio-icon {
    font-size:18px;
    padding:6px;
  }
}


@media (max-width:360px){.hide-sm{
  display:none;
}}

.vote-card {
  background-color: lighten($calm,40) !important;

  .list .item {
    min-height: 0px;
  }

  .item-radio input:checked ~ .item-content {
      background: $calm;
  }

  .card {
    background-color: lighten(grey,40) !important;
    padding:0;
    min-height:0px;
  }

}


.isBrowserView {
    overflow-y: auto !important;
  }

// .list.display-toggle.item {
//   width:32px;
//   padding:0px;
//   margin:0px;
//   top:0px;
//   position:relative;
//   float:none !important;
//
//   .item-toggle .toggle {
//     position: relative;
//     top: 10px;
//     right: 16px;
//     z-index: 3;
//   }
//
//   .track
//   {
//   margin:0;
//   // width:auto;
//   float:right;
//   position:relative;
//   }
// }

//
// .toggle input + .track{
//   background-color: $calm;
// }


.display-toggle.toggle.toggle-positive input:checked + .track {
  background-color: $calm;
}


.item.card-status-header {
  border-bottom:1px solid !important;
}

.bar .button.back-button {
  max-width: 128px;
  width:128px;
}


.header-menu {
    @media (max-device-width: 1024px) {
        background-attachment: scroll;
    }
}



.separator {
  font-size:1.3em;
}

#logo {
  height:48px !important;
  width:auto;
}






.button-calm.md-hue-0 {
  background-color:  $calm;
}

.button-calm.md-hue-1 {
  background-color:  adjust_hue($calm, 10);
}
.button-calm.md-hue-2 {
  background-color:  adjust_hue($calm, 20);
}
.button-calm.md-hue-3 {
  background-color:  adjust_hue($calm, 30);
}



.force-wrap {
  white-space: normal !important;
}










button.button-positive:hover{
    -background-color: white;
}
ion-header-bar.bar-positive:hover{
    -background-color: white;
}
ion-item.highlighted:hover  {
    -background-color: white;
}
ion-item.item-avatar {
    // padding-left:20px;
    // padding-right:75px;
    // width:%
}

.bar.bar-positive {
    border-color: $navbarbkg !important;
    background-color: $navbarbkg !important;
    //#background-image: linear-gradient(0deg, #aa191e, #aa191e 50%, transparent 50%);
    color: $navbartext !important;
}


.bar-positive .button.button-clear {
    color: $navbartext !important;

}

.header-menu a ,
.header-menu .item,
.header-menu .item-complex .item-content, .header-menu .item-radio .item-content
.header-menu a:hover ,
.header-menu .item:hover,
.header-menu .item-complex .item-content:hover,
.header-menu .item-radio .item-content:hover
{
  background-color: transparent;
}

#logo {
height:42px !important;
}


.row .col span {
  white-space: normal !important;
}
