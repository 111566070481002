
/**
 * Loading
 * --------------------------------------------------
 */

.loading-container {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;

  z-index: $z-index-loading;

  @include display-flex();
  @include justify-content(center);
  @include align-items(center);

  @include transition(0.2s opacity linear);
  visibility: hidden;
  opacity: 0;

  &:not(.visible) .icon {
    display: none;
  }
  &.visible {
    visibility: visible;
  }
  &.active {
    opacity: 1;
  }

  .loading {
    padding: $loading-padding;

    border-radius: $loading-border-radius;
    background-color: $loading-bg-color;

    color: $loading-text-color;

    text-align: center;
    text-overflow: ellipsis;
    font-size: $loading-font-size;

    h1, h2, h3, h4, h5, h6 {
      color: $loading-text-color;
    }
  }
}
